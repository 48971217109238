import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import _ from 'lodash'
import moment from 'moment'
import { Link } from 'react-router-dom'

import MonthPicker from '../../components/month-picker'
import { getMetersListPagination, getBuildingsSettings } from '../../__data__/actions/readings'
import { makeCheckedBuildings } from '../../__data__/selectors/common'
import { makeMetersObject, makeBuildingsSettingsMeterTypes, makeBuildingsSettingsSubmitDays } from '../../__data__/selectors/readings'
import { URL_LIST } from '../../__data__/constants'

import style from './style.css'
import { default as List } from './components/list'
import { default as Card } from './components/card'
import {checkWritePermissionForSection} from "../../__data__/actions/permissions";

function Component(props) {
    const { checkedBuildings, getMetersListPagination, getBuildingsSettings, metersObject, meterTypes, isAllBuildings, submitDays } = props
    const { startDay, endDay } = submitDays

    const [date, setDate] = useState({month: moment().format("MM"), year: moment().format("YYYY")})

    const dateString = `${date.month}${date.year}`
    const isAvailableByPerms = checkWritePermissionForSection('Показания')
    const currentDay = parseInt(moment().format("D"))
    const isRightDate = currentDay > parseInt(startDay) && currentDay < parseInt(endDay)
    const buttonText = '➕ Внести показания'

    useEffect(() => {
        if (checkedBuildings) {
            getMetersListPagination(checkedBuildings, dateString)
            getBuildingsSettings(checkedBuildings)
        }
    }, [checkedBuildings, dateString])

    const [selected, setSelected] = useState(null)

    const handleClick = (event, flatId) => {
        if (selected !== flatId) {
            setSelected(flatId)
        } else {
            setSelected(null)
        }
    }

    const activeItem = _.find(metersObject.list, i => i.flatId === selected)

    return (
        <div className={style.container}>
            <div className={style.headerWrapper}>
                <div className={style.header}>
                    <div className={style.headerLeft}>
                        <div className={style.tabs}>
                            <MonthPicker
                                year={date.year}
                                month={+date.month}
                                handleChange={setDate}
                            />
                        </div>
                        {!isAvailableByPerms || !isRightDate ? (
                            <Link to={undefined} className={style.disabledLink}>{buttonText}</Link>
                        ) : (
                            <Link to={URL_LIST.metersForm} className={style.addLink}>{buttonText}</Link>
                        )}
                    </div>
                    <div className={style.headerRight}>
                        <div className={style.submitDates}>{`с ${startDay} по ${endDay} числа месяца`}</div>
                    </div>
                </div>
            </div>
            <div className={style.list}>
                <List
                    metersObject={metersObject}
                    handleClick={handleClick}
                    selected={selected}
                    isAllBuildings={isAllBuildings}
                    meterTypes={meterTypes}
                    getMetersListPagination={getMetersListPagination}
                    dateString={dateString}
                    checkedBuildings={checkedBuildings}
                />
                { activeItem && <Card { ...activeItem } handleClick={setSelected} /> }
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    checkedBuildings: makeCheckedBuildings(),
    metersObject: makeMetersObject(),
    meterTypes: makeBuildingsSettingsMeterTypes(),
    submitDays: makeBuildingsSettingsSubmitDays(),
})

const mapDispatchToProps = {
    getMetersListPagination,
    getBuildingsSettings,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
