import React from 'react'
import { Link } from 'react-router-dom'

import { URL_LIST } from '../../__data__/constants'
import PageToggle from '../../components/page-toggle'

import style from './style.css'
import { checkWritePermissionForSection } from "../../__data__/actions/permissions";

const items = [
    {
        id: 0,
        title: 'Сотрудники',
        url: URL_LIST['settingsEmployeesPermissions'],
    },
    {
        id: 2,
        title: 'Объекты',
        url: URL_LIST['settingsBuildings'],
    },
    {
        id: 3,
        title: 'Компания',
        url: URL_LIST['settingsCompanyProfile'],
    },
]

function Component(props) {
    const pathName = window.location.pathname
    const isEmployeesPage = pathName.indexOf(URL_LIST['settingsEmployeesPermissions']) === 0
    const isAvailableByPerms = checkWritePermissionForSection('Сотрудники')
    const buttonText = '➕ Добавить сотрудника'

    return (
        <div className={style.container}>
            <div className={style.header}>
                <PageToggle items={items} />
                {isEmployeesPage && !isAvailableByPerms ? (
                    <Link to={undefined} className={style.disabledLink}>{buttonText}</Link>
                ) : (
                    isEmployeesPage && <Link className={style.addLink} to={URL_LIST.settingsAddEmployee}>{buttonText}</Link>
                )}
            </div>
            <div>
                {props.children}
            </div>
        </div>
    )
}

export default Component
