import { useMediaQuery } from 'react-responsive';

const useMediaQueries = () => {
    // const isBigScreen = useMediaQuery({ minWidth: 1824 });
    // const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
    const isMobile = useMediaQuery({ maxWidth: 858 });
    const isTablet = useMediaQuery({ minWidth: 859, maxWidth: 1000 });
    const isLandscape = useMediaQuery({ orientation: 'landscape' });
    const isPortrait = useMediaQuery({ orientation: 'portrait' });

    return {
        // isBigScreen,
        // isDesktopOrLaptop,
        isMobile,
        isTablet,
        isLandscape,
        isPortrait,
    };
};

export default useMediaQueries;